import { PublicationFormat } from '~/interfaces/publisher';
import { type Option } from '~/interfaces';

export const categoriesWithGroups = [
  {
    id: 11,
    name: 'Семья и дети',
    categories: [
      {
        id: 1018,
        name: 'Беременность и материнство',
      },
      {
        id: 1019,
        name: 'Мероприятия и товары для детей',
      },
      {
        id: 1028,
        name: 'Воспитание и развитие',
      },
    ],
  },
  {
    id: 10,
    name: 'Образование',
    categories: [
      {
        id: 240,
        name: 'История',
      },
      {
        id: 242,
        name: 'Наука, популярная наука',
      },
      {
        id: 247,
        name: 'Иностранные языки',
      },
      {
        id: 1015,
        name: 'Дошкольное образование',
      },
      {
        id: 1016,
        name: 'Школьное образование',
      },
      {
        id: 1017,
        name: 'Проф. образование',
      },
      {
        id: 1029,
        name: 'Образовательные книги и курсы',
      },
    ],
  },
  {
    id: 8,
    name: 'Еда',
    categories: [
      {
        id: 214,
        name: 'Кулинария и рецепты',
      },
      {
        id: 1013,
        name: 'Правильное питание',
      },
    ],
  },
  {
    id: 6,
    name: 'Здоровье и медицина',
    categories: [
      {
        id: 210,
        name: 'ЗОЖ',
      },
      {
        id: 213,
        name: 'Фитнес, йога',
      },
      {
        id: 215,
        name: 'Медицина',
      },
    ],
  },
  {
    id: 5,
    name: 'Дом и сад',
    categories: [
      {
        id: 203,
        name: 'Дизайн интерьера',
      },
      {
        id: 204,
        name: 'Строительство и ремонт',
      },
      {
        id: 205,
        name: 'Дача, сад, огород',
      },
    ],
  },
  {
    id: 9,
    name: 'Карьера',
    categories: [
      {
        id: 1014,
        name: 'Вакансии IT',
      },
      {
        id: 1030,
        name: 'Поиск работы',
      },
    ],
  },
  {
    id: 7,
    name: 'Красота и мода',
    categories: [
      {
        id: 211,
        name: 'Стиль, мода',
      },
      {
        id: 212,
        name: 'Красота, уход за собой',
      },
      {
        id: 1012,
        name: 'Услуги',
      },
    ],
  },
  {
    id: 3,
    name: 'Культура и искусство',
    categories: [
      {
        id: 222,
        name: 'Музыка',
      },
      {
        id: 225,
        name: 'Литература',
      },
      {
        id: 1006,
        name: 'Фотографии',
      },
      {
        id: 1007,
        name: 'Живопись и архитектура',
      },
      {
        id: 1008,
        name: 'Театры, музеи, выставки',
      },
    ],
  },
  {
    id: 17,
    name: 'Авторский контент',
    categories: [
      {
        id: 1000,
        name: 'Лайфстайл',
      },
      {
        id: 1026,
        name: 'Фан-каналы',
      },
      {
        id: 1027,
        name: 'Звезды',
      },
    ],
  },
  {
    id: 1,
    name: 'Транспорт',
    categories: [
      {
        id: 201,
        name: 'Автомобили/Мото',
      },
      {
        id: 1001,
        name: 'Прочий транспорт',
      },
      {
        id: 1002,
        name: 'Самокаты/Велосипеды',
      },
    ],
  },
  {
    id: 2,
    name: 'Экономика и бизнес',
    categories: [
      {
        id: 249,
        name: 'Реклама и маркетинг',
      },
      {
        id: 256,
        name: 'Инвестиции и крипта',
      },
      {
        id: 1003,
        name: 'Юриспруденция',
      },
      {
        id: 1004,
        name: 'Бизнес',
      },
      {
        id: 1005,
        name: 'Экономика',
      },
    ],
  },
  {
    id: 19,
    name: 'Общество',
    categories: [
      {
        id: 238,
        name: 'Военная тематика',
      },
      {
        id: 243,
        name: 'Политика',
      },
      {
        id: 258,
        name: 'СМИ и новости',
      },
    ],
  },
  {
    id: 4,
    name: 'Животные и природа',
    categories: [
      {
        id: 1009,
        name: 'Домашние животные',
      },
      {
        id: 1010,
        name: 'Дикие животны и природа',
      },
      {
        id: 1011,
        name: 'Экология',
      },
    ],
  },
  {
    id: 13,
    name: 'Развлечения',
    categories: [
      {
        id: 220,
        name: 'Знакомства',
      },
      {
        id: 227,
        name: 'Юмор',
      },
      {
        id: 230,
        name: 'Азартные игры',
      },
      {
        id: 236,
        name: 'Активный отдых',
      },
      {
        id: 245,
        name: 'Охота и рыбалка',
      },
      {
        id: 259,
        name: 'Кино и сериалы',
      },
      {
        id: 260,
        name: 'Хендмейд и рукоделие',
      },
      {
        id: 1022,
        name: 'Анимэ',
      },
      {
        id: 1032,
        name: 'Настольные игры',
      },
    ],
  },
  {
    id: 14,
    name: 'Путешествия',
    categories: [
      {
        id: 1020,
        name: 'Страны',
      },
      {
        id: 1031,
        name: 'Туризм',
      },
    ],
  },
  {
    id: 15,
    name: 'Спорт',
    categories: [
      {
        id: 229,
        name: 'Автогонки',
      },
      {
        id: 231,
        name: 'Бокс и Единоборства',
      },
      {
        id: 234,
        name: 'Футбол',
      },
      {
        id: 1021,
        name: 'Баскетбол',
      },
      {
        id: 1023,
        name: 'Хоккей',
      },
      {
        id: 1024,
        name: 'Теннис',
      },
      {
        id: 1025,
        name: 'Другие виды спорта',
      },
    ],
  },
  {
    id: 16,
    name: 'IT, Информационные технологии',
    categories: [
      {
        id: 207,
        name: 'Киберспорт/Видеоигры',
      },
      {
        id: 208,
        name: 'Программирование и софт',
      },
      {
        id: 209,
        name: 'Hi-tech новости, технологии',
      },
      {
        id: 239,
        name: 'Графика и веб-дизайн',
      },
      {
        id: 1033,
        name: 'Онлайн инструменты',
      },
    ],
  },
  {
    id: 12,
    name: 'Региональные сообщества',
    categories: [
      {
        id: 202,
        name: 'Региональные новости',
      },
      {
        id: 224,
        name: 'Афиши и события',
      },
    ],
  },
  {
    id: 18,
    name: 'Духовность',
    categories: [
      {
        id: 237,
        name: 'Астрология, эзотерика',
      },
      {
        id: 244,
        name: 'Религия',
      },
      {
        id: 252,
        name: 'Мотивация и успех',
      },
      {
        id: 253,
        name: 'Психология и отношения',
      },
    ],
  },
  {
    id: 20,
    name: 'Хардкор 18+',
    categories: [
      {
        id: 262,
        name: 'Эротика',
      },
      {
        id: 263,
        name: 'Шок-контент',
      },
    ],
  },
  {
    id: 21,
    name: 'Другое',
    categories: [
      {
        id: 216,
        name: 'Недвижимость',
      },
      {
        id: 218,
        name: 'Объявления',
      },
      {
        id: 250,
        name: 'Скидки, акции',
      },
      {
        id: 261,
        name: 'Лайфхаки',
      },
    ],
  },
];

export const mappedCategories = new Map();

categoriesWithGroups.forEach(({ categories }) => {
  categories.forEach(({ id, name }) => {
    mappedCategories.set(id, name);
  });
});

export const publicationFormats: Option<PublicationFormat>[] = [
  { value: PublicationFormat.post24h, name: 'Пост 24ч' },
  { value: PublicationFormat.post48h, name: 'Пост 48ч' },
  { value: PublicationFormat.post72h, name: 'Пост 72ч' },
  { value: PublicationFormat.fixedInTop, name: 'Закреп 24ч' },
  { value: PublicationFormat.postWithoutDelete, name: 'Без удаления' },
  { value: PublicationFormat.story, name: 'История' },
  { value: PublicationFormat.repost, name: 'Репост' },
];
